<template>
  <vuestic-widget :loading="fetching || loading" class="user-edit-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Edit User</span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <div class="userEditForm">
      <ValidationObserver 
        v-slot="{ handleSubmit, invalid, dirty }"
        ref="userEditForm"
      >
        <form @submit.prevent="handleSubmit(onFormSubmit)">
          <div class="row basic-form">
            <div class="col-md-4 mb-4">
              <div class="avatar-wrapper">
                <avatar-upload
                  @upload="uploadImage"
                  :avatar="formData.avatar"
                ></avatar-upload>
              </div>
            </div>
            <div class="col-md-8 mb-4">
              <div class="row">
                <div class="col-md-12">
                  <text-input name="first_name" v-model="formData.first_name" label="First Name" validate="required" />
                </div>
                <div class="col-md-12">
                  <text-input name="last_name" v-model="formData.last_name" label="Last Name" validate="required" />
                </div>
                <div class="col-md-12">
                  <text-input type="email" name="Email" v-model="formData.email" label="Email" validate="required" />
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-9">
                      <phone-number-input name="Phone" label="call forward number" v-model="formData.phone" :allowValidation="false" :required="false"/>
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <text-input name="extension" label="Extension" placeholder="EXT number" tooltip="You can enter Extension Number here. ( Max 5 digits ). Leave it blank if there isn't any" :validate="{regex: /^\d{1,5}$/}" v-model="formData.extension" class='voice-extension' />
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-4">
                  <textarea-input
                    name="Signature"
                    label="Signature"
                    max-length="255"
                    :rows="3"
                    v-model="formData.signature"
                  />
                </div>  
              </div>
            </div>
          </div>

          <div class="row mt-1" v-if="formData && formData.role ==='BusinessUser'">
            <div class="col-md-12 mt-3 mb-4">
              <h5 class="text-primary font-weight-bold">User Type</h5>
            </div>
            <div class="col-md-12">
              <div class="checkbox abc-checkbox abc-checkbox-primary">
                <input type="radio" name="user_type" id="admin-support" value="1" v-model="formData.user_type">
                <label for="admin-support">
                  <span class="abc-label-text">Admin Support <a v-b-tooltip.hover title="Admin Support has access to Analytic, Contacts, Calls, Installs and Settings pages" class="fa fa-question-circle tooltip-icon" /></span>
                </label>
              </div>
              <div class="checkbox abc-checkbox abc-checkbox-primary">
                <input type="radio" name="user_type" id="marketing-user" value="2" v-model="formData.user_type">
                <label for="marketing-user">
                  <span class="abc-label-text">Marketing User <a v-b-tooltip.hover title="Marketing User has access to Inbox, Analytic, Contacts, Broadcasts, Calls, Automations, Installs and Settings pages" class="fa fa-question-circle tooltip-icon" /></span>
                </label>
              </div>
              <div class="checkbox abc-checkbox abc-checkbox-primary">
                <input type="radio" name="user_type" id="sales-user" value="3" v-model="formData.user_type">
                <label for="sales-user">
                  <span class="abc-label-text">Sales User <a v-b-tooltip.hover title="Sales User has access to Messages, Calls and Settings pages" class="fa fa-question-circle tooltip-icon" /></span>
                </label>
              </div>

               <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="radio" name="user_type" id="custom-type" value="4" v-model="formData.user_type">
              <label for="custom-type">
                <span class="abc-label-text">Custom Type <a v-b-tooltip.hover title="Custom user has access to the pages selected below" class="fa fa-question-circle tooltip-icon" /></span>
              </label>
            </div>
            </div>
          </div>

          <div class="row mt-1" v-if="showCustomTypePagesSection">
           <div class="col-md-12 mt-3 mb-4">
            <h5 class="text-primary font-weight-bold">Custom Type Pages</h5>
          </div>

          <div class="col-md-12 custom-type-pages-wrapper">
            <div v-for="(item, index) in custom_type_pages" :key="index" class="checkbox abc-checkbox abc-checkbox-primary custom-type-page-item">
              <input type="checkbox" :name="`schedule-${index}`" :id="`1custom-type-group-${index}`" :value="item.id" v-model="formData.custom_type_pages">
              <label :for="`1custom-type-group-${index}`">
                <span class="abc-label-text">{{ item.label }}</span>
              </label>
            </div>
          </div>
        </div>

          <div v-if="showNotificationSection" class="row mt-1">
            <div class="col-md-12 mt-3 mb-4">
              <h5 class="text-primary font-weight-bold">Notification</h5>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 d-flex align-items-center">
                  <div class="flex-fill">
                    <phone-number-input name="Phone" label="phone number" v-model="formData.sms_phone" :allowValidation="false" :required="false"/>
                  </div>
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.sms_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <div class="flex-fill">
                    <text-input :disabled="true" type="email" name="email" placeholder="Enter email" v-model="formData.email" label="Email" validate="required|email"/>
                  </div>
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.email_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                </div>
                <div class="col-md-12 d-flex align-items-center">
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.new_assign_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                  <div class="flex-fill ml-3">
                    <b>Turn on to get notification when the lead is transferred to you even when notifications are turned off. This way you only get notified when a lead is assigned to you.</b>
                  </div>
                </div>
                <div class="col-md-12 d-flex align-items-center mt-4">
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.push_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                  <div class="flex-fill ml-3">
                    <b>Turn on to get push notification to your mobile app.</b>
                  </div>
                </div>
                <div v-if="showNewMessageNotification" class="col-md-12 d-flex align-items-center mt-4">
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.new_message_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                  <div class="flex-fill ml-3">
                    <b>Turn on to get notification from contacts with no agent assigned.</b>
                  </div>
                </div>
                <div class="col-md-12 d-flex align-items-center mt-4">
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.business_hours_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                  <div class="flex-fill ml-3">
                    <b>Keep all notifications out of business hours and send them all together when business hour starts</b>
                  </div>
                </div>
                <div class="col-md-12 d-flex align-items-center mt-4">
                  <div class="ml-2 switch-small">
                    <vuestic-switch
                      v-model="formData.taks_reminder_notification"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">On</span>
                      <span slot="falseTitle">Off</span>
                    </vuestic-switch>
                  </div>
                  <div class="flex-fill ml-3">
                    <b>Turn on to get notification for task reminder before</b>
                  </div>
                </div>
                <div v-if="formData.taks_reminder_notification" class="col-md-12 mt-4 ml-5">
                  <div class="row">
                    <div class="col-md-3 mt-4">
                      <div class="form-group">
                        <div class="input-group">
                          <label class="control-label">Duration</label>
                          <text-input type="number" name="duration" v-model="formData.taks_reminder_duration" :validate="'required'" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <SelectInput label="" v-model="taskReminderDurationPedriod" name="durationPeriod" :options="duratioOptions" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-5">
              <p class="text-primary font-weight-bold">Notifications are set to be sent out by default 24 hours a day, if you want notification during a set hours uncheck 24 hours and set your desired notification hours.</p>
              <div class="hours mt-4">
                <div
                  class="row align-items-center"
                  v-for="(obj, index) in formData.hours"
                  :key="index"
                >
                  <div class="col-1">
                    <strong>{{ obj.weekDay.toUpperCase() }}</strong>
                  </div>
                  <div class="col">
                    <time-selector
                      v-model="obj.openTime"
                      label="Open"
                      :disabled="obj.fullOpen"
                    />
                  </div>
                  <div class="col">
                    <time-selector
                      v-model="obj.closeTime"
                      label="Close"
                      :disabled="obj.fullOpen"
                    />
                  </div>
                  <div class="col">
                    <div class="checkbox abc-checkbox abc-checkbox-primary">
                      <input
                        type="checkbox"
                        name="schedule"
                        :id="`country-group-${index}`"
                        value="Daily"
                        v-model="obj.fullOpen"
                      />
                      <label :for="`country-group-${index}`">
                        <span class="abc-label-text">24 hours</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-2">
                    <vuestic-switch
                      v-model="obj.open"
                      class="align-middle"
                      :offcolor="true"
                    >
                      <span slot="trueTitle">Open</span>
                      <span slot="falseTitle">Closed</span>
                    </vuestic-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <router-link class="btn btn-primary btn-danger mr-2" :to="{name: 'business.users.index'}">
                <span>Back</span>
              </router-link>
              <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
                <span v-else>Update</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div class="userPasswordForm mt-5">
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="passwordForm">
        <form @submit.prevent="handleSubmit(handleUpdatePassword)">
          <div class="row">
            <div class="col-md-6">
              <text-input name="NewPassword" v-model="newPassword.password" label="New Password"
                validate="required" ref="NewPassword" type="password"/>
            </div>
            <div class="col-md-6">
              <text-input name="ConfirmPassword" v-model="newPassword.password_confirmation" label="Confirm password"
                :validate="`required|password:${newPassword.password}`" type="password"/>
            </div>
          </div>
          <div class="mt-2 text-center">
            <button class="btn btn-primary" :disabled="processing || invalid">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
              <span v-else>Update</span>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </vuestic-widget>
</template>

<script>
  import { mapState } from 'vuex';
  import TimeSelector from "../../forms/TimeSelector";
  import TextEditorPicker from "../../common/TextEditorPicker";
  import AvatarUpload from "../../common/AvatarUpload";
  import axios from 'axios'
import { throwStatement } from '@babel/types';

  export default {
    components: { 
      TimeSelector,
      TextEditorPicker,
      AvatarUpload,
    },
    data() {
      return {
        loading: false,
        formData: {
          id: '',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          extension: '',
          sms_phone: '',
          email_notification: false,
          sms_notification: false,
          notification_full_time: true,
          notification_start_time: '09:00AM',
          notification_end_time: '05:00PM',
          new_assign_notification: true,
          push_notification: true,
          new_message_notification: true,
          business_hours_notification: false,
          taks_reminder_notification: true,
          taks_reminder_duration: 1,
          taks_reminder_duration_period: 'hour',
          signature: '',
          hours: [],
          custom_type_pages: []
        },
        newPassword: {
          password: null,
          password_confirmation: null,
        },
        taskReminderDurationPedriod: undefined,
        custom_type_pages : [
          {
            id: 'analytic_page', label: 'Analytic Page'
          },
          {
            id: 'inbox_page', label: 'Inbox Page'
          },
          {
            id: 'calls_page', label: 'Calls Page'
          },
          {
            id: 'tasks_page', label: 'Tasks Page'
          },
          {
            id: 'contacts_page', label: 'Contacts Page'
          },
          {
            id: 'broadcast_page', label: 'Broadcast Page'
          },
          {
            id: 'automation_page', label: 'Automation Page'
          },
          {
            id: 'installs_page', label: 'Installs Page'
          },
          {
            id: 'numbers_page', label: 'Numbers Page'
          },
          {
            id: 'tags_page', label: 'Tags Page'
          },
          {
            id: 'users_page', label: 'Users Page'
          },
          {
            id: 'departments_page', label: 'Departments Page'
          },
          {
            id: 'message_template_page', label: 'Message Template Page'
          },
          {
            id: 'blacklist/whitelist_page', label: 'Blacklist/Whitelist Page'
          }
        ]
      };
    },

    created () {
    },

    computed: {
      ...mapState('businessUser', {
        fetching: 'fetching',
        didFetch: 'didFetch',
        processing: 'processing',
      }),
      showNotificationSection() {
        return ['2', '3', '4'].includes(this.formData.user_type) || this.formData.user_type == ""
      },
      showNewMessageNotification() {
        return ['3', '4'].includes(this.formData.user_type)
      },
      showCustomTypePagesSection() {
        return ['4'].includes(this.formData.user_type)
      },
      duratioOptions() {
        return [
        { value: 'seconds', label: "seconds" },
        { value: 'minutes', label: "minutes" },
        { value: 'hour', label: "hour(s)" },
        { value: 'day', label: "day(s)" },
        { value: 'week', label: "week(s)" },
        { value: 'month', label: "month(s" },
        { value: 'year', label: "year(s)" },
        ]
      },
    },
    mounted() {
      if (this.$route.params.id) {
        const id = this.$route.params.id
        this.loadData(id);
      } else {
        this.redirectOnError()
      }
    },
    methods: {
      redirectOnError() {
        this.$router.push({ name: 'business.users.index' });
      },

      uploadImage(event) {
        event.waitUntil(new Promise((resolve, reject) => {
          let postData = new FormData();
          postData.append('image', event.image);
          axios.post(`/v1/businesses/users/${this.formData.id}/image`, postData)
            .then(({data}) => {
              if (data.success) {
                this.initData(data.data);
                resolve();
              } else {
                reject();
              }
            });
        }))
      },

      handleUpdatePassword() {
        const userId = this.$route.params.id;
        if (userId)
        {
          this.$store.dispatch('businessUser/updatePassword', {id: userId, params:this.newPassword})
            .then(() => {
              this.newPassword = this.$options.data().newPassword
              this.$refs.passwordForm.reset()
            })
            .catch(() => {
            })
        }
      },

      loadData(id) {
        this.loading = true;
        this.$store
          .dispatch('businessUser/get', id)
          .then((res) => {
            this.loading = false;
            this.initData(res.data);
          })
          .catch((err) => {
            this.loading = false;
            this.redirectOnError()
          })
      },

      initData(data) {
        this.formData = 
        {
          id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
          send_email: false,
          phone: data.phone,
          extension: data.extension,
          sms_phone: data.sms_phone,
          new_assign_notification: data.new_assign_notification,
          signature: data.signature,
          role: data.role,
          user_type: data.user_type,
          avatar: data.avatar,
          ...data.settings,
        }
        this.taskReminderDurationPedriod = this.duratioOptions.find(item => item.value == this.formData.taks_reminder_duration_period)
      },

      onFormSubmit() {
        const param = {
          id: this.formData.id,
          data: {
            ...this.formData,
          }
        }
        this.$store
          .dispatch('businessUser/update', param)
          .then((data) => {
            this.initData(data)
          })
          .catch((err) => {
            let errors = err.response.data.errors
            if (errors)
            {
              this.$refs.userEditForm.setErrors(errors)
            }
          })
      }
    },

  };
</script>

<style lang="scss" scoped>
  .userEditForm,
  .userPasswordForm {
    max-width: 1280px;
  }
  .avatar-wrapper {
    width: 300px; 
  }
  .selectedInstallWrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    li {
      padding: 3px 10px;
      color: #ffffff;
      background-color: $rb-blue;
      border-radius: 10px;
      margin: 5px;
      font-weight: bold;
    }
  }
  .checkbox-label {
    --primaryColor: #3578c6;
  }
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
  }
  .custom-type-pages-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .custom-type-page-item {
      min-width: 200px;
    }
  }
</style>